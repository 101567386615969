import React from "react";

const ReportSubDetails = ({ reportDetails }) => {
  return (
    <>
      <h4 className="text-black  mb-3 mt-3">
        Job No: {`#${reportDetails?.job_id?.job_no}`}
      </h4>
      <h5 className="text-black  mb-3 mt-3">Pests</h5>
      {reportDetails?.pests?.map((pest) => (
        <h6 className="text-color">{pest?.name}</h6>
      ))}
      <h5 className="text-black  mb-3 mt-3">Chemicals</h5>
      {reportDetails?.chemicals?.map((chemical) => (
        <h6 className="text-color">{chemical?.name}</h6>
      ))}
      <h5 className="text-black  mb-3 mt-3">Tools</h5>
      {reportDetails?.tools?.map((tool) => (
        <h6 className="text-color">{tool?.name}</h6>
      ))}
      <h5 className="text-black  mb-3 mt-3">Recommendations</h5>
      <span>{reportDetails?.recommendations}</span>
      <hr />
    </>
  );
};

export default ReportSubDetails;
